.settings {
  display: flex;
  flex-direction: column;
  background-color: white;
  justify-content: flex-start;
  box-sizing: border-box;
  overflow: auto;
}

.bottom {
  display: flex;
  flex-direction: column;

  flex-grow: 1;
  padding-top: 10vh;
}

.leftSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-right: none;
  padding: 15px;
  border-bottom: 2px solid rgba(230, 230, 230, 1);
  box-sizing: border-box;
}

.profile {
  margin-bottom: 5vh;
  font-weight: bold;
  color: rgba(108, 108, 108, 1);
}
.new {
  height: 50%;
  width: 100%;
  background-color: rgba(107, 112, 92, 1);
}
.alter {
  width: 200px;
  height: 200px;
  margin-top: 3vh;

  border-radius: 50%;
  border: 5px soid rgba(165, 165, 141, 1);

  background-color: #e5f1f6;
  display: flex;
  justify-content: center;
  align-items: center;
}
.span {
  color: rgba(165, 165, 141, 1);
  font-size: 60px;
  font-weight: 100;
}
.avatar {
  width: 60%;
  height: auto;
  margin: 1rem 0;
}

.name2 {
  margin-top: 2vh;
  font-weight: 700;
  font-size: 30px;
  text-align: center;
}

.location2 {
  margin-top: -1vh;
  font-size: 0.9rem;
  color: rgba(108, 108, 108, 1);
  text-align: center;
}

.rightSection {
  width: 100%;
  margin: 10px 0;
  padding: 0 5%;
  box-sizing: border-box;
}

.formHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.formTitle {
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
}

.divider {
  border: none;
  border-top: 1px solid rgba(229, 229, 229, 1);
  margin: 15px 0;
}

.formRow {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  gap: 5px;
}

.formGroup {
  width: 100%;
  height: 30%;
  margin-right: 0;
}

.formGroup label {
  display: block;
  margin-bottom: 5px;
  font-size: 0.85rem;
  font-weight: bold;
  color: #333;
}

.formGroup input {
  width: 90%;
  height: 30%;
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.9rem;
}

/* .formActions {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  flex-wrap: wrap;
} */
.formActions {
  display: flex;
  gap: 10px;
  justify-content: space-between; /* Center the button horizontally */
  align-items: center; /* Optional: Center vertically if needed */
  flex-wrap: wrap;
  width: auto; /* Adjusts width dynamically */
  position: absolute;
  bottom: 9%;
  left: 50;
  }
.cancelButton,
.saveButton {
  flex: 1;
  min-width: 100px;
  margin-bottom: 10px;
  text-align: center;
  padding: 8px 26px;
  justify-content: center;
}
.cancelButton {
  color: rgba(108, 108, 108, 1);
  border: 2px solid rgba(165, 165, 141, 1);
  border-radius: 8px;
}

/* .saveButton {
  background-color: rgba(1, 129, 187, 1);
  border: none;
  border-radius: 8px;

  font-size: 1rem;
  cursor: pointer;
  color: rgba(255, 255, 255, 1);
  
} */
.saveButton {
  background-color: rgba(1, 129, 187, 1);
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  color: rgba(255, 255, 255, 1);
  padding: 10px 20px; /* Optional: Better button styling */
  
  display: flex; /* Ensures alignment works */
   /* Moves button content to the left */
  align-items: center; /* Centers text vertically */
  
  margin-left: 0; /* Ensures the button itself aligns to the left */
}


@media (min-width: 768px) {
  .bottom {
    flex-direction: row;
  }

  .leftSection {
    width: 35%;
    border-right: 2px solid rgba(230, 230, 230, 1);
    border-bottom: none;
  }

  .rightSection {
    width: 65%;
    margin: 30px 80px;
    padding: 0;
  }

  .formRow {
    flex-direction: row;
  }

  .formGroup {
    margin-right: 10px;
  }

  .formGroup:last-child {
    margin-right: 0;
  }
}

@media (max-width: 375px) {
  .title {
    font-size: 1.25rem;
  }

  .formGroup input {
    width: 100%;
  }

  .tabs {
    margin-bottom: 10px;
  }

  .tab {
    font-size: 0.8rem;
    padding: 10px;
  }
}
