.playground-container {
  display: flex;
  flex-direction: column;
}

/* .code-editor {
  height: 75vh;
  overflow: auto;
} */

.code-editor:focus {
  outline: none !important;
}

.test-cases {
  flex: 1;
  overflow-y: auto;
}

.gutter {
  background-color: #eee;
  background-repeat: no-repeat;
  background-position: 50%;
}

.gutter.gutter-vertical {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=");
  cursor: row-resize;
}

.button {
  padding: 0 12px;
  background-color: #0080bb;
  border: none;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 0;
  height: 32px;
  line-height: 32px;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.button:hover {
  background-color: #006699;
}

.button-container {
  display: flex;
  gap: 5px;
  align-items: center;
}

.split {
  display: flex;
  height: 85vh;
}

.split-pane {
  height: 100%;
  overflow-y: auto;
  margin-bottom: 2.5rem;
}

.gutter {
  background-color: #eee;
  background-repeat: no-repeat;
  background-position: 50%;
}

.gutter.gutter-horizontal {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==");
  cursor: col-resize;
}

.split-pane-code {
  height: 85vh;
  margin-bottom: 2.5rem;
}

.run-button {
  background-color: #28a745;
}

.run-button:hover {
  background-color: #218838;
}

.language-select {
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f8f9fa;
  cursor: pointer;
  min-width: 120px;
  margin-right: 15px;
  height: 35px;
  display: flex;
  align-items: center;
}

.language-select:hover {
  border-color: #bbb;
  background-color: #f0f0f0;
}

.language-select option {
  padding: 8px;
  background-color: white;
  color: #333;
}

.language-select option:hover {
  background-color: #f0f0f0;
}

.language-selector {
  display: flex;
  align-items: center;
  gap: 8px;
}

.language-label {
  font-size: 16px;
  color: #333;
  font-weight: 500;
  min-width: fit-content;
}

.code-editor .cm-editor {
  height: 100%;
}

.code-editor .cm-theme-dark {
  background-color: #1e1e1e !important;
  height: 100%;
}

.code-editor .cm-theme-light {
  background-color: #ffffff !important;
  height: 100%;
}

.preference-controls {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px;
}

.bottom-container {
  display: flex;
  flex-direction: column;
  padding: 8px 15px;
  background-color: #f8f9fa;
  border-radius: 4px;
  margin: 5px 10px;
  width: calc(100% - 20px);
  overflow: auto;
}

.controls-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  flex-shrink: 0;
}

.custom-input-container {
  position: relative;
}

.custom-input-label {
  display: flex;
  align-items: center;
  margin-right: auto;
}

.custom-input-label input[type="checkbox"] {
  margin-right: 8px;
}

.custom-input-textarea {
  height: 100px;
  padding: 4px 6px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-family: monospace;
  font-size: 13px;
  resize: none;
  margin-top: 4px;
  margin-bottom: 4px;
}

.custom-input-textarea:focus {
  outline: none;
  border-color: #0080bb;
}

.playground-button-container {
  display: flex;
  gap: 5px;
  margin-left: auto;
  align-items: center;
}

.test-cases-button {
  background-color: #0080bb;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.3s ease;
}

.test-cases-button:hover {
  background-color: #0056b3;
  color: white;
}
