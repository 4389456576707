.elegant-card {
    border: 1px solid #e1e1e1;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    transition: box-shadow 0.3s ease-in-out;
  }
  
  .elegant-card:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  .elegant-header {
    background-color: white;
    color: #495057;
    border-bottom: 1px solid #e1e1e1;
    padding: 15px;
    border-radius: 10px 10px 0 0;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .centered-loader {
    text-align: center;
  }
  
  