.App {
    background-color: white;
  }
  
  .Auth-form-container {
    display: flex;
    justify-content: space-between;
    min-height: 100vh;
    background-image: url('../../assets/homepage/backgroundImage.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  
  .left-section {
    flex: 1;
    padding: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .left-section h1 {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 20px;
    color: white;
  }
  
  .left-section h2 {
    font-size: 32px;
    margin-bottom: 16px;
    color: white;
  }
  
  .left-section p {
    font-size: 18px;
    color: rgba(255, 255, 255, 0.8);
  }
  
  .right-section {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .Auth-form {
    width: 420px;
    box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
    padding: 30px;
    border-radius: 8px;
    background-color: white;
  }
  
  .Auth-form-content {
    text-align: center;
  }
  
  .Auth-form-content h3 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 12px;
  }
  
  .sign-in-text {
    color: #666;
    margin-bottom: 24px;
  }
  
  label {
    font-size: 14px;
    font-weight: 600;
    color: rgb(34, 34, 34);
  }