.problem-container {
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.scrollable-content {
  flex: 1;
  overflow-y: auto;
}

.content-padding {
  padding: 1.25rem;
}

.problem-meta {
  display: flex;
  align-items: center;
  margin-top: 0.75rem;
}

.difficulty-tag {
  color: #6b8e23;
  background-color: rgba(107, 142, 35, 0.15);
  display: inline-block;
  border-radius: 21px;
  padding: 0.625rem;
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: capitalize;
}

.problem-header {
  width: 100%;
}

.problem-title {
  display: flex;
  gap: 1rem;
}

.problem-name {
  flex: 1;
  margin-right: 0.5rem;
  font-size: 1.125rem;
  color: black;
  font-weight: 500;
  padding-top: 10px;
}

.problem-statement {
  color: black;
  font-size: 0.875rem;
}

.paragraph {
  margin-top: 0.75rem;
}

.examples {
  margin-top: 1rem;
}

.example {
  margin-top: 1rem;
}

.example-title {
  font-weight: 500;
  color: black;
}

.example-card {
  background-color: #f0f0f0;
  padding: 1rem;
  border-radius: 5px;
  margin-top: 0.5rem;
}

.constraints {
  margin-top: 1.25rem;
}

.constraints-title {
  color: black;
  font-size: 0.875rem;
  font-weight: 500;
}

.constraints-list {
  color: black;
  margin-left: 1.25rem;
  list-style-type: disc;
}

.constraints-list li {
  margin-top: 0.5rem;
}

.constraints-list code {
  color: black;
}

.constraints-list strong {
  font-weight: 500;
}

.markdown {
  margin-top: 0.75rem;
  color: black;
}

.markdown code {
  background-color: #f0f0f0;
  padding: 0.2rem 0.4rem;
  border-radius: 3px;
}
