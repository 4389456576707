html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
}
.main2{
  display:flex;
  flex-direction:column;
  height: 100vh;
}


.main22 {
  display: flex;
  font-family: "Be Vietnam Pro";
  box-sizing: border-box;
  overflow: hidden;
  flex-grow:1;

}


.chatlist {
  width: 30%;

  height: 100%;
  border-right: 3px solid rgb(169, 162, 162);
  display: block;
}
.logos {
  display: flex;
  margin-top: 3px;
  background-color: rgba(255, 255, 255, 1);
  border-bottom: 3px solid rgb(169, 162, 162);
  height: 70px;
  width: 100%;
}

.edgent {
  width: 78px;
  height: 44px;
  margin-top: 10px;
  margin-left: 16px;
}

.frame {
  margin-top: 22px;
  margin-right: 24px;
  width: 60px;
  height: 16px;
}
.chat-item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  border-bottom: 1px solid rgb(169, 162, 162);
  height: 80px;
}
.profile-pic {
  width: 30px;
  height: 30px;
  margin-left: 8px;
  margin-top: 3px;
}

.chat-details {
  display: block;
  flex-grow: 1;
  margin-right: 20px;
  margin-top: 5px;
  margin-left: 50px;
}

.chat-name {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3px;
  margin-left: 10px;
  font-size: 14px;
}
.chat-message {
  margin-top: -1px;
  margin-left: 10px;
  font-size: 0.8em;
  color: #595a5f;
}

.chatlist-timestamp {
  display: inline-block;
  color: #595a5f;
  font-size: small;
  margin-right: 17px;
  position: relative;
}
.chatlist .unread-count {
  min-width: 20px;

  padding: 2px 6px;
  border-radius: 12px;
  background-color: green;
  color: white;
  font-size: 0.8em;
  text-align: center;
  position: absolute;
  right: 0;
  top: 100%;
  margin-top: 4px;
}
.chatlist .chat-item {
  position: relative;
}
.edgent_logo {
  width: 250px;
  height: 250px;
}

.chat {
  flex-grow: 1;
  /* width:65%; */
  height: 100%;
  display: none;
  border-left: 1px solid rgb(169, 162, 162);
}

.title-bar {
  background-color: rgba(1, 129, 187, 1);
  width: 100%;
  height: 73px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  color: #fff;
  font-size: 1.2rem;
  position: relative;
}

.back-button {
  display: none;
  background: none;
  border: none;
  color: white;
  font-size: 2rem;
  margin-right: 10px;
  cursor: pointer;
}

.chat-item2 {
  display: flex;
  align-items: center;
}

.profile-pic2 {
  width: 40px;
  height: 40px;
}
.initial-avatar {
  width: 100%;
  height: 100%;
  background-color: white;
  color: #3498db;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: bold;
}
.chat-details2 {
  display: block;
  margin-left: 25px;
}

.chat-name2 {
  font-size: 18px;
  color: #ffffff;
}

.chat-message2 {
  margin-top: -4px;
  margin-left: 2px;
  color: #ffffff;
  font-size: small;
}

.message-space {
  background-color: rgba(232, 234, 235, 1);
  width: 100%;
  height: calc(100% - 146px);
  padding: 20px;
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.message {
  display: flex;
  word-wrap: break-word;
  word-break: break-word;
}

.message-left {
  justify-content: flex-start;
}

.message-right {
  justify-content: flex-end;
}

.message-content {
  max-width: 90%;
  padding: 13px;
  border-radius: 15px;
  background-color: white;
  position: relative;
  margin: 5px;
  min-width: 20%;
  min-height: 55px;
}

.message-content-left {
  border-top-left-radius: 0;
}

.message-content-right {
  border-top-right-radius: 0;
}
.message-left .message-content {
  margin-left: 0;
  margin-right: auto;
}

.message-right .message-content {
  margin-left: auto;
  margin-right: 0;
}

.timestamp {
  font-size: 0.6em;
  color: gray;
  position: absolute;
  bottom: 2px;
  right: 10px;
}

.bottom {
  height: 275px;
  padding: 10px;
  background-color: rgba(183, 194, 199, 1);
  max-height: 275px;
}

.form {
  display: flex;
}

.message-box {
  display: flex;
  align-items: center;
  padding: 7px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  width: calc(100% - 20px);
  margin: 0px auto;
}

.type-message {
  flex: 1;
  padding: 7px;
  border: none;
  border-radius: 5px;
  outline: none;
  box-sizing: border-box;
}

button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0px 5px;
}
.chatlist .chat-item {
  display: flex;
  align-items: center;
  margin-top: -9px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  height: 80px;
}

.chatlist .chat-item:hover {
  background-color: #f0f0f0;
}

.chatlist .chat-item.selected {
  background-color: #e0e0e0;
}

.chatlist .chat-item .profile-pic img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin-top: -2px;
}

.chatlist .chat-item .chat-details {
  flex-grow: 1;
  margin-left: 20px;
}

.chatlist .chat-item .timestamp {
  font-size: 0.8em;
  color: #888;
  margin-top: 2px;
  margin-right: 30px;
}
.avatar-circle {
  width: 40px;
  height: 40px;
  background-color: #3498db;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 1.2rem;
}
.no-chat-selected {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  text-align: center;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
  margin: 200px auto;
}

/* Media query for larger screens */
@media (min-width: 769px) {
  .chat {
    display: block;
  }
}

/* Mobile view - Hide chat list when chat is visible */
@media (max-width: 768px) {
  .back-button {
    display: inline-block;
  }
  .main22.chat-visible .chatlist {
    display: none;
  }

  .main22.chat-visible .chat {
    display: block;
  }

  .chatlist {
    wrap: no-wrap;
    width: 100%;
    box-sizing: border-box;
  }
  .chat-name {
    font-size: 100%;
  }
  .chat-message {
    font-size: 60%;
  }

  .edgent_logo {
    width: 0;
    height: 0;
  }

  .chat-message2 {
    font-size: 50%;
  }

  .sidebar {
    width: 10%;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
