.container {
  /* min-height: 100vh; */
  /* display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5; */
}

.box {
  margin-left: 120px;
}
.paper {
  padding: 0px;
  width: 90%;
  /* max-width: 1000px; */
  /* margin: auto; */
  margin-top: 40px;
  margin-left: 50px;
}

.question-box {
  border: 1px solid rgba(0, 0, 0, 0.1); /* Light gray border */
  border-radius: 0 10px 10px 10px;
  padding: 16px;
  margin-bottom: 16px;
}

/* .submitted-answer {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0 10px 10px 10px;
  padding: 16px;
  background-color: #e4f1f7;
  margin-top: 16px;
  margin-bottom: 16px;
} */

.submitted-answer {
  border: 1px solid rgba(0, 0, 0, 0.1); /* Light gray border */
  border-radius: 0 10px 10px 10px;
  padding: 16px;
  background-color: #e4f1f7;
  margin-top: 16px;
  margin-bottom: 16px;
  max-height: 160px; /* Adjust as needed */
  overflow-y: auto;
  word-wrap: break-word;
  flex: 1;
}

.editor-container {
  background-color: #e9e9e9;
  padding: 10px 45px;
  border-radius: 10px 10px 0 0;
  margin-top: 140px;
  display: flex;
  justify-content: space-between;
}

.react-quill {
  height: 250px;
  margin-top: -16px;
  border-radius: 0 0 20px 20px;
}

.option-box {
  border: 1px solid rgba(0, 0, 0, 0.1); /* Light gray border */
  border-radius: 0 10px 10px 10px;
  margin-bottom: 16px;
  padding: 16px;
  /* margin-left: 100px; */
}

.options-container {
  margin-left: 12px;
}

/* .button-container {
  margin-top: 50px;
} */

.success-message {
  margin-top: 50px;
}

.answer-box {
  background-color: aqua;
  width: 100%;
  padding: 100px 100px;
  padding-top: 800px;
}

.submitted-option {
  background-color: rgb(210, 240, 210);
}

.button-container {
  margin-top: 500px; /* Increase this value as needed to move the button further down */
  display: flex;
  justify-content: center; /* Center the button horizontally if needed */
}

.submit-button {
  margin-top: 80px; /* Adjust the margin to fine-tune the position */
}

.submit-button-container {
  position: fixed;
  bottom: 20px; /* Adjust as needed */
  right: 20px; /* Adjust as needed */
  z-index: 1000; /* Ensure it stays on top of other elements */
}
