.css-1opldwd{
    justify-content: space-between !important;
    }
    .css-1yuhvjn{
      width: 50%;
    }
    .css-jdutu8{
      height:auto !important;
    }
    .css-1wsi274-MuiPickersToolbar-content{
      display: none !important;
    }
    .css-gqp4xl-MuiPickersLayout-root .MuiPickersLayout-actionBar{
      display: none !important;
    }
    .MuiBox-root.css-1ufrzn0{
      flex-direction: column !important;
    }
    .css-1umkse3-MuiTypography-root {
      display: none;
    }
    .css-15uzny8-MuiTypography-root {
      display: none;
    }
    .css-1ivupt2-MuiTypography-root{
      width: 100% !important;
    }
    .css-1rtg91x-MuiDateCalendar-root {
      border: 1px solid #efe8e8;
      border-radius: 7px;
    }
    .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
      border: 1px solid #efe8e8;
      margin-top: 24px;
    }
    .css-1apy7mz {
      gap: 20px;
    }
    .css-11xur9t-MuiPaper-root-MuiTableContainer-root{
      max-height: 380px !important;
    }