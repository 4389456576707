/* src/components/TestCaseModal.css */
.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    overflow-y: auto;
  }
  
  .modal-content {
    background-color: white;
    padding: 40px;
    border-radius: 8px;
    width: 70%;
    max-width: 800px;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    height: 80vh;
    overflow-y: auto;
  }
  
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
    color: #333;
    z-index: 10;
  }
  .test-case-title {
    font-size: 16px;
    font-weight: bold;
  }
  
  .test-case {
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 15px;
    background-color: #f9f9f9;
  }
  
  .test-case p {
    margin: 5px 0;
  }
  
  .test-case strong {
    display: block;
    margin-bottom: 5px;
  }
  
  .test-case input,
  .test-case output {
    background-color: #f1f1f1;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
    font-family: monospace;
  }
  
  .test-case-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    background-color: #f1f1f1;
  }
  
  .score {
    background-color: #eee;
    padding: 5px 10px;
    border-radius: 12px;
    font-size: 12px;
  }
  
  .toggle-icon {
    font-size: 16px;
  }
  
  .test-case-details {
    padding: 15px;
    display: none;
  }
  
  .test-case-details.expanded {
    display: block;
  }
  
  .test-case-details p {
    margin: 5px 0;
    font-size: 14px;
  }
  
  .test-case-details strong {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
  }
  
  .test-case-details .value {
    background-color: #f1f1f1;
    padding: 10px;
    border-radius: 4px;
    font-family: monospace;
    margin-bottom: 10px;
  }
  
  select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px 12px;
    font-size: 14px;
    cursor: pointer;
    width: 100px;
    margin-left: 10px;
    position: relative;
  }
  
  select:focus {
    outline: none;
    border-color: #007bff;
  }
  
  select::-ms-expand {
    display: none;
  }
  
  .select-container {
    position: relative;
    display: inline-block;
  }
  
  .select-container::after {
    content: '▼';
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    font-size: 12px;
    color: #333;
  }